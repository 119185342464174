<template>
  <div class="dashboard h-screen flex items-center scrolling-auto justify-center mt-10 mb-10">
    <div class="w-full md:w-4/6">
      <div class="flex  logo ">
        <div class="w-1/3 left">
          <img src="../../assets/icons/transfer.svg" class="h-20 w-20 p-4 justify-start">
        </div>
        <div class="w-2/3 flex justify-center text-right">
          <img src="../../assets/icons/ic-logo.svg" class="h-32 w-40 justify-center ">
        </div>
        <div class="w-1/3 flex justify-end">
          <img src="../../assets/icons/account.svg" class="h-20 w-20 p-4 pt-4 mb-4 justify-end" >

        </div>

      </div>
      
      <!-- <div class="flex justify-center mb-10">
        <p class="font-semibold text-2xl md:text-3xl text-center">Nolido Cash Transfert</p>
      </div> -->
      <div class="header flex md:flex-row">
            <div class="w-1/3 text-left">
             <h3 class="text-xl text-black">{{ currentUser?.fullname }}</h3>
             <h3 class="text-sm text-black">{{ currentUser?.email }}</h3>
             <h3 class="text-sm text-black">{{ currentUser?.code }} {{ currentUser?.phone }} {{ currentUser?.name }}</h3>
  
           </div> 
           <div class="w-2/3 flex justify-center text-right">
             <!-- <div @click="initiaTransaction" class="rounded-xl flex items-center h-12 justify-center cursor-pointer text-white mx-3 p-3 text-xl create-transaction-button">
               {{ $t('initTransaction') }}
             </div> -->
             <!-- <div @click="takePayment" class="rounded-xl flex items-center h-12 justify-center cursor-pointer text-white p-3 text-xl create-transaction-button">
        {{ $t('payNow') }} </div>  -->
        <!-- <div @click="resetPassword" class="rounded-xl flex items-center h-12 justify-center cursor-pointer text-white p-3 text-xl create-transaction-button">
               {{ $t('Reinstaliser Mot de passe') }}
             </div> -->
           </div>
           <div class="w-1/3 flex justify-end">
             <div @click="logout" class="rounded-xl flex items-center h-12 justify-center cursor-pointer text-white p-3 text-xl logout">
               {{ $t('logout') }}
             </div>
           </div> 
         </div>

      <div class="transaction-list " v-if="!initTransaction && !showSuccess && !enterDetail">
         <div class="header flex md:flex-row">
           <div class="w-1/3 text-left">
             <h3 class="text-xl text-black">{{ currentUser?.fullname }}</h3>
             <h3 class="text-sm text-black">{{ currentUser?.email }}</h3>
             <h3 class="text-sm text-black">{{ currentUser?.code }} {{ currentUser?.phone }} {{ currentUser?.name }}</h3>
  
           </div>
           <div class="w-2/3 flex justify-center text-right">
             <div @click="initiaTransaction" class="rounded-xl flex items-center h-12 justify-center cursor-pointer text-white mx-3 p-3 text-xl create-transaction-button">
               {{ $t('initTransaction') }}
             </div>
             <!-- <div @click="takePayment" class="rounded-xl flex items-center h-12 justify-center cursor-pointer text-white p-3 text-xl create-transaction-button">
        {{ $t('payNow') }} </div>  -->
        <!-- <div @click="resetPassword" class="rounded-xl flex items-center h-12 justify-center cursor-pointer text-white p-3 text-xl create-transaction-button">
               {{ $t('Reinstaliser Mot de passe') }}
             </div> -->
           </div>
           <div class="w-1/3 flex justify-end">
             <div @click="logout" class="rounded-xl flex items-center h-12 justify-center cursor-pointer text-white p-3 text-xl logout">
               {{ $t('logout') }}
             </div>
           </div>
         </div>
        <!-- <div class="table-content">
          <table class="border-collapse border border-green-800 p-2 w-full text-center mt-4">
            <thead>
            <tr>
              <th class="border border-green-600 p-2">Date</th>
              <th class="border border-green-600 p-2">Transaction ID</th>
              <th class="border border-green-600 p-2">Envoyé à</th>
              <th class="border border-green-600 p-2">Numéro Bénéficiaire</th>
              <th class="border border-green-600 p-2">Réseau</th>
              <th class="border border-green-600 p-2">Total</th>
              <th class="border border-green-600 p-2">Status</th>
            </tr>
            </thead>
            <tbody>
            <tr class="text-black"   v-for="(item, index) in allTransaction" :key="item+index">
              <td class="border border-green-600 p-2">{{ new Date(item.createdAt).toLocaleString()}} </td>
              <td class="border border-green-600 p-2">{{ item.id }} {{ item.orderId }} </td>
              <td class="border border-green-600 p-2">{{ item.fullnameBef }}</td>
              <td class="border border-green-600 p-2">{{ item.phoneNumber }}</td>
              <td class="border border-green-600 p-2">{{ item.want.label }}</td>
              <td class="border border-green-600 p-2">{{ item.amount }}</td>
              <td class="border border-green-600 p-2">
              <span v-if="typeof item.status === 'undefined' || item.status === 'pending'">
               Encours
             </span>
                <span v-if="item.status === 'end'">
               Terminé
             </span>
                <span v-if="item.status === 'failled'">
               Echec
             </span></td>
            </tr>
            </tbody>
          </table>
  
        </div> -->
        <div>
    </div>
       </div>
       <div class=" border-indigo-300 dark:bg-gray-800 dark:border-gray-700 border-2 ">
        <div class="header flex md:flex-row ">
            
          <!-- <img src="../../assets/icons/ic-logo.svg" class="h-32 w-40 justify-center"> -->

          <div class="w-1/3 left">
        </div>
        <div class="w-2/3 flex justify-center mt-3 text-right">
          <img src="../../assets/icons/orange.svg" class="h-32 w-40 justify-center ">
          <img src="../../assets/icons/wave.svg" class="h-32 w-40 justify-center ">
          <img src="../../assets/icons/R.svg" class="h-32 w-40 justify-center ">

        </div>
        <div class="w-1/3 flex justify-end">

        </div>
         </div>
         <div v-if="initTransaction && !showSuccess && !enterDetail" class=" flex  init-transaction-form w-full  items-center justify-center  shadow-md rounded-2xl p-2 m-1">
        
        <div class="w-auto md:w-1/2">
          <div class="form mt-8 w-full">
         <div class="flex mt-2 md:flex-row flex-col">
            <!--     <div class="form-item md:w-1/2 w-full">
                <multiselect
                  v-model="transactionType"
                  :options="getOptions()"
                  track-by="id"
                  label="label"
                  deselect-label=""
                  select-label=""
                  class="select-mu"></multiselect>
              </div>  --> 
              <div class="form-item md:ml-4  w-full md:mt-0 mt-2">
               
               <h3 class="text-xl font-normal text-black">{{ $t('montant') }}</h3>
                <input type="number" class="text-xl bg-gray-300 border number-input rounded-xl h-16 w-full p-2 text-black" v-model="amount">
                <!-- <input type="number" class="text-xl border number-input rounded-xl h-16 w-full p-2 text-black" v-model="amount" placeholder="Montant mini. 10.000 XOF / Montant max. 1.800.000 XOF" style="color: red;"> -->

                <p  class="text-red-500">{{ $t('minimum') }}</p>
                <input class="text-xl border bg-gray-300 number-input rounded-xl h-16 w-full p-2 text-black" v-model="getTotalAmount"  disabled>
   
              </div>
            </div>
          </div>
          <div class="form mt-8  w-full">
           
           <!-- <h3 class="text-xl font-normal text-black">{{ $t('have') }}</h3> -->
           <div class="form-item  md:w-full w-full mt-4" v-if="wantValue.id !== 'cashycoin'">
              
              <div>
               <h3 class="text-xl font-normal text-black">{{ $t('fullnameBef') }}</h3>
              <input type="text" class="text-xl bg-gray-300 mt-2 border number-input rounded-xl h-16 w-full p-2 text-black" v-model="fullnameBef">
              </div>
            </div>
           <!-- <h3 class="text-xl font-normal text-black">{{ $t('want') }}</h3> -->
            <div class="flex mt-2 md:flex-row flex-col">
              <div class="form-item bg-gray-300 md:w-1/2 w-full mt-2  h-16">
                <multiselect
                  v-model="wantValue"
                  :options="getSecondList()"
                  track-by="id"
                  label="label"
                  deselect-label=""
                  select-label=""
                  class="select-mu"></multiselect>
              </div>
              <div class="form-item md:ml-4 md:w-1/2 w-full md:mt-0 mt-2">
              <input type="text" placeholder="Numéro de téléphone" class=" bg-gray-300 text-xl bg-gray-300  mt-2 border number-input rounded-xl h-16 w-full p-2 text-black" v-model="phoneNumber" @blur="validatePhoneNumber">
              <!-- Affichage du message d'erreur -->
               <p v-if="phoneNumberError" class="text-red-500">{{ $t('Veillez saisir le bon indicatif numéro') }}</p>
              </div>
            </div>
            
            <div class="form-item  md:w-full w-full mt-4" v-if="wantValue.id === 'cashycoin'">
              <h3 class="text-xl font-normal text-black">{{ $t('addressCashycoin') }}</h3>
              <input type="text" class="text-xl  mt-2 border number-input rounded-xl h-16 w-full p-2 text-black" v-model="cashycoinId">
            </div>
            <div class="form-item  md:w-full w-full mt-4">
             <h3 class="text-xl font-normal text-black">{{ $t('transactionId') }}</h3>
             <multiselect
              v-model="justifyId"
              :options="getMotifs()"
              track-by="id"
              label="label"
              deselect-label=""
              select-label=""
              class="select-mu"
              :preselect-first="true">
            </multiselect>
             <!-- <input type="text" class="text-xl  mt-2 border number-input rounded-xl h-16 w-full p-2 text-black" v-model="justifyId"> -->
           </div>
            <div @click="closeSuccess" class="mt-4 h-16 w-full flex items-center cursor-pointer rounded-xl next-button justify-center text-center text-xl text-white">
              <span v-if="!showLoader" >{{ $t('next') }}</span>
              <pulse-loader v-if="showLoader"  :color="color"></pulse-loader>
            </div>
          </div></div>
          <div
       class=" inset-0 bg-gradient-to-r from-cyan-400 to-sky-500 shadow-lg transform -skew-y-6 sm:skew-y-0 sm:-rotate-6 sm:rounded-3xl">
     </div>
       </div>
       </div>
     
      <div v-if="showSuccess" class="success flex items-center justify-center md:w-full p-4">
        <div class=" text-center md:w-1/2 w-full shadow rounded-2xl p-4">
          <h3 class="flex items-center justify-center"><svg-icon name="ic_success" class="h-20 w-20" original></svg-icon></h3>
          <h3 class="text-xl font-bold text-black mt-4 uppercase"> {{ $t('successInit') }} </h3>
          <p class="mt-3 text-xl text-black p-2">
            {{ $t('message') }}
          </p>
          <div @click="closeSuccessDone" class="mt-4 h-16 w-full flex items-center cursor-pointer rounded-xl next-button justify-center text-center text-xl text-white">
            <span  >{{ $t('end') }}</span>
          </div>
        </div>
      </div>
      <div class="detail flex items-center justify-center md:w-full p-4" v-if="enterDetail && !showSuccess">
        <div class=" md:w-1/2 w-full shadow rounded-2xl p-4">
          <h3 class="text-xl  text-black mt-4 "> {{ $t('pleaseMakeDeposit') }} </h3>
          <div class="form-item  w-full mt-4">
            <h3 class="text-xl  text-black mt-4 "> {{ $t('address') + ' ' + getData.label }} </h3>
            <input class="text-xl border number-input rounded-xl h-16 w-full p-2 text-black mt-3" v-model="getData.id"  disabled>
          </div>
          
          <table v-if="transactionType.length > 0" class="border-collapse border border-green-800 p-2 w-full text-center mt-4">
        <thead>
          <tr>
            <th class="border border-green-600 p-2">ID</th>
            <th class="border border-green-600 p-2">Label</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in transactionType" :key="index">
            <td class="border border-green-600 p-2">{{ item.id }}</td>
            <td class="border border-green-600 p-2">{{ item.label }}</td>
          </tr>
        </tbody>
      </table>
  
      <table v-if="wantValue.length > 0" class="border-collapse border border-green-800 p-2 w-full text-center mt-4">
        <thead>
          <tr>
            <th class="border border-green-600 p-2">ID</th>
            <th class="border border-green-600 p-2">Label</th>
            <div class="border-green-800"></div>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in wantValue" :key="index">
            <td class="border border-green-600 p-2">{{ item.id }}</td>
            <td class="border border-green-600 p-2">{{ item.label }}</td>
          </tr>
        </tbody>
      </table>
      
          <div @click="saveTransaction" class="mt-4 h-16 w-full flex items-center cursor-pointer rounded-xl next-button justify-center text-center text-xl text-white">
            <span v-if="!showLoader" >{{ $t('next') }}</span>
            <pulse-loader v-if="showLoader"  :color="color"></pulse-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import firebase from 'firebase'
  import { db } from '../../main'
  import databaseRef from '../../utils/database.ref'
  import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
  import Multiselect from 'vue-multiselect'
  import 'vue-select/dist/vue-select.css'
  import vSelect from 'vue-select'
  import axios from 'axios';
  
  
  export default {
    name: 'index',
    components: { Multiselect, PulseLoader, vSelect
   },
    data () {
      return {
        currentUser: {},
        initTransaction: true,
        amount: 0,
        showLoader: false,
        color: '#ffffff',
        responseData: null,
        loading: false, 
        orderId: null,
        phoneNumberError: false, // Variable pour suivre l'état de la validation
        transactionType: {
          id: 'cashycoin',
          label: this.$t('cashycoin'),
        },
        options: [
          {
            id: 'orangeMoney',
            label: this.$t('orangeMoney')
          },
          {
            id: 'freeMoney',
            label: this.$t('freeMoney')
          },
          {
            id: 'waves',
            label: this.$t('waves')
          },
          {
            id: 'expresso',
            label: this.$t('expresso')
          },
  
  
        ],
        secteurs: [
          {
            id: 'particulier',
            label: this.$t('Particulier')
          },
          {
            id: 'entreprise',
            label: this.$t('Entreprise')
          },
  
        ],
        motifs: [
        {
            id: 'assistance',
            label: this.$t('assistance')
          },
          {
            id: 'aide',
            label: this.$t('aide')
          },
         
          {
            id: 'financement',
            label: this.$t('financement')
          },
          {
            id: 'construction',
            label: this.$t('construction')
          },
          {
            id: 'paiement',
            label: this.$t('Paiement de loyer, facture ou commande')
          },
  
        ],
        
        allTransaction: [],
        cashycoinId: '',
        showSuccess: false,
        enterDetail: false,
        phoneNumber: '',
        fullnameBef: '',
        orderId: null,
        wantValue: {
          id: 'orangeMoney',
          label: this.$t('orangeMoney')
        },
        justifyId: ''
      }
    },
    firestore: function () {
      return {
        currentUser: db.collection(databaseRef.users).doc(firebase.auth().currentUser.uid),
        allTransaction: db.collection(databaseRef.transactions).doc(firebase.auth().currentUser.uid).collection(databaseRef.transactions).orderBy('createdAt', 'desc')
      }
    },
    methods: {
      async saveTransaction () {
        let refId = '';
        if (this.justifyId !== '') {
          const vm = this
          this.showLoader = true
          const transaction = {
            id: '',
            orderId: this.generateRandomOrderId(),
            fullname: this.currentUser.fullname,
            phone: this.currentUser.phone,
            email: this.currentUser.email,
            have: this.transactionType,
            amount: Number.parseInt(Math.round(this.amount)),
            cashycoinId: this.cashycoinId,
            phoneNumber: this.phoneNumber,
            fullnameBef: this.fullnameBef,
            proofId: this.justifyId,
            createdAt: new Date().getTime(),
            status: 'pending',
            want: this.wantValue,
            receiveAmount: this.getTotalAmount,
            userId: firebase.auth().currentUser.uid
          }
          const reciveD = transaction.phoneNumber !== '' ? transaction.phoneNumber : transaction.cashycoinId
          transaction.id = db.collection(databaseRef.transactions).doc().id
          refId = transaction.id;
          db.collection(databaseRef.transactions).doc(transaction.id)
            .set(transaction)
            .then(() => {
              const url = `https://us-central1-rema-da2f4.cloudfunctions.net/sendMailForCashycoin?dest=${vm.currentUser.email}&username=${vm.currentUser.fullname}&ticketId=${transaction.id}&sendData=${transaction.amount.toString() + ' ' + transaction.have.label}&receiveData=${transaction.receiveAmount.toString() + ' ' + transaction.want.label}&receiveAddress=${reciveD}`
              console.log(url)
              fetch(url)
                .then(() => {
                  setTimeout(() => {
                    vm.showSuccess = true
                    vm.showLoader = false
                  }, 1000)
                })
                .catch((err) => {
                  alert(err)
                })
            })
            .catch((err) => {
              alert(err)
            })
        } else {
          alert('Please enter jusitify Id')
        }
        this.takePayment(refId);
      },
    
      initiaTransaction () {
        this.initTransaction = true
      },
      closeSuccess () {
        this.initTransaction = true
        this.showSuccess = false
        this.enterDetail = false
  
           // Appelez la méthode makePayment
           this.saveTransaction();
      },
      closeSuccessDone () {
        this.initTransaction = true
        this.showSuccess = false
        this.enterDetail = false
      },
      getMotifs () {
        return this.motifs
      },
      getSecondList () {
        let firstList = this.options.filter((item) => {
          return item.id !== this.secteurs.id
        })
        if (this.transactionType.id !== 'cashycoin') {
          firstList = this.options.filter((item) => {
            return item.id === 'cashycoin'
          })
        } 
        if (this.transactionType.id === 'particulier') {
          firstList = this.options.filter((item) => {
            return item.id === 'cashycoin' && this.secteurs()
          })
        } else {
          firstList = this.options.filter((item) => {
            return item.id !== 'cashycoin'
          })
        }
        return firstList
      },
      getFirstList () {
        return this.options
      },
      getOptions () {
        return this.secteurs
      },
//       generateRandomOrderId() {
//         const orderId = response.data.order_id;
// return orderId
//         // const timestamp = new Date().getTime(); // Obtenez le timestamp actuel
//         // const randomId = Math.random().toString(36).substring(2, 15); // Générez un identifiant aléatoire
//         // return `NOLIDO_TRANSFERT_${timestamp}`; // Combinez le timestamp et l'identifiant aléatoire
//       },
      makePayment() {
        // Définissez les données de la requête
        const requestData = {
          order_id: this.generateRandomOrderId(),
          reference: "NOLIDO_TRANSFERT",
          amount: this.getTotalAmount,
          currency: "XOF",
          return_url: "https://www.google.com",
          cancel_url: "https://www.google.com",
          state: this.currentUser.name,
        };
  
        // Définissez les en-têtes de la requête
        const headers = {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'country-code': 'sn',
          'category': 'SALE',
          'lang': 'fr',
          'authorization': 'Bearer 2122a551-8d10-3424-84ed-d72bf0fb6187'
        };
  
        // Effectuez la requête POST en utilisant Axios https://api.bizao.com/debitCard/v2
        axios.post('debitCard/v2', requestData, { headers })
          .then(response => {
            // Gérez les données de réponse
            console.log('Réponse de la requête POST :', response.data);

              // Récupérez l'`order_id` de la réponse
              const orderId = response.data.order_id;
              console.log('Order ID:', orderId);
            // Rediriger l'utilisateur vers la page de paiement en utilisant l'URL de paiement fournie
               // Store orderId in Firestore
      db.collection('transactions').add({
        orderId: orderId,
        // Autres champs de la transaction
      })
      .then(docRef => {
        console.log("Order ID stored with ID:", docRef.id);
        // Update orderId in the component data
        this.orderId = orderId;
      })
      .catch(error => {
        console.error("Error adding order ID:", error);
      });

            window.open(response.data.payment_url, '_blank');
          })
          .catch(error => {
            // Gérez les erreurs
            console.error('Erreur lors de la requête POST :', error);
          });
      },
//       takePayment() {
//         // Définissez les données de la requête
//         const requestData = {
//           sender : {
//         nom: this.currentUser.fullname,
//         prenom: "toni",
//         phone: this.currentUser.phone,
//         paysEnvoi: this.currentUser.name
//     },
//     receiver : {
//       nom: this.fullnameBef,
//         prenom: this.fullnameBef,
//         phone: this.phoneNumber,
//         montant: this.amount,
//         frais: 10,
//         montantFinal: this.getTotalAmount,
//         reseau: this.want.label
//     }
//         };
  
//         // Définissez les en-têtes de la requête
//      // Make the HTTP POST request using Axios
// axios.post('https://api.nolidomoneytransfer.com/api/transfer', requestData, {
//     headers: {
//         'Content-Type': 'application/json'
//     }
// })
  
// .then(response => {
//     // Handle success
//     const { payment_url } = response.data;
//     if (payment_url) {
//         // Open payment_url in a new tab
//         window.open(response.data.payment_url, '_blank');
//     } else {
//         console.error('No payment URL received');
//     }
// })
// .catch(error => {
//     // Handle error
//     console.error('Error:', error);
// });
//       },
      takePayment(id) {
        // Définissez les données de la requête
        const requestData = {
          sender : {
        nom: this.currentUser.fullname,
        prenom: "toni",
        phone: this.currentUser.phone,
        paysEnvoi: this.currentUser.name
    },
    receiver : {
      nom: this.fullnameBef,
        prenom: this.fullnameBef,
        phone: this.phoneNumber,
        montant: this.amount,
        frais: 10,
        montantFinal: this.getTotalAmount,
        reseau: this.wantValue.label
    }
        };
  
        // Définissez les en-têtes de la requête
        const headers = {
          'Content-Type': 'application/json'
        };
  
        // Effectuez la requête POST en utilisant Axios https://api.bizao.com/debitCard/v2
        axios.post('https://api.nolidomoneytransfer.com/api/transfer', requestData, { headers })
          .then(response => {
            // Gérez les données de réponse
            console.log('Réponse de la requête POST :', response.data);

              // Récupérez l'`order_id` de la réponse
              const orderId = response.data.order_id;
              console.log('Order ID:', orderId);
            // Rediriger l'utilisateur vers la page de paiement en utilisant l'URL de paiement fournie
               // Store orderId in Firestore
      db.collection('transactions').doc(id).update({
        orderId
      });
      // .then(docRef => {
      //   console.log("Order ID stored with ID:", docRef.id);
      //   // Update orderId in the component data
      //   this.orderId = orderId;
      // })
      // .catch(error => {
      //   console.error("Error adding order ID:", error);
      // });

      window.open(response.data.payment_url, '_self');
          })
          .catch(error => {
            // Gérez les erreurs
            console.error('Erreur lors de la requête POST :', error);
          });
          
      },
      generateRandomOrderId(response) {
        if (!response || !response.data || !response.data.order_id) {
            // Gestion de cas où la réponse ou l'ID de commande est manquant
            console.error('Missing response or order_id in response');
            return null;
        }

        // Récupérez l'ID de commande de la réponse
        const orderId = response.data.order_id;
        return orderId;
    },
      // Dans votre méthode Vue.js où vous voulez récupérer les détails de la transaction
      async fetchTransactionDetails() {
        try {
          const orderId = NOLIDO_TRANSFERT_1713664273462;

          // Définir les en-têtes de la requête
          const headers = {
            'Content-Type': 'application/json',
            // Ajoutez ici d'autres en-têtes nécessaires
            'country-code': 'sn',

            'authorization': 'Bearer 2122a551-8d10-3424-84ed-d72bf0fb6187'

          };
          const url = `getStatus/"NOLIDO_TRANSFERT_1713664273462`;

          const response = await axios.get(url, { headers });
          const transactionDetails = response.data; // Les détails de la transaction seront ici

          // Afficher les détails de la transaction
          console.log('Détails de la transaction:', transactionDetails);

          // Vous pouvez également affecter les détails de la transaction à une variable dans les données du composant
          this.transactionDetails = transactionDetails;
        } catch (error) {
          // Gérer les erreurs
          console.error('Erreur lors de la récupération des détails de la transaction:', error);
        }
      },
      goDetail () {
        if (this.amount && this.amount !== '') {
          if (this.wantValue.id === 'cashycoin' && this.cashycoinId !== '') {
            this.enterDetail = true
            this.initTransaction = false
          } else {
            if (this.wantValue.id !== 'cashycoin' && this.phoneNumber !== '') {
              this.enterDetail = true
              this.initTransaction = false
            } else {
              alert('All input are required')
            }
          }
        } else {
          alert('All input are required')
        }
      },
      logout () {
        const vm = this
        firebase.auth().signOut()
          .then(() => {
            console.log('here')
            vm.$router.push('/login')
          })
      },
      allTransac () {
        db.collection("users").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
          console.log(`${doc.id} => ${doc.data()}`);
      });
  });
      },
      validatePhoneNumber() {
        // Réinitialiser l'état de l'erreur
        this.phoneNumberError = false;
  
        // Effectuer la validation
        if (this.wantValue.id === 'freeMoney' && !/^7[56]/.test(this.phoneNumber)) {
          // Le numéro de téléphone ne correspond pas aux critères
          this.phoneNumberError = true;
        } else if (this.wantValue.id === 'orangeMoney' && !/^7[78]/.test(this.phoneNumber)) {
          // Le numéro de téléphone ne correspond pas aux critères
          this.phoneNumberError = true;
        }
      },
      resetPassword() {
      const email = this.currentUser.email
      if (email) {
        firebase.auth().sendPasswordResetEmail(email)
          .then(() => {
            alert("Un e-mail de réinitialisation de mot de passe a été envoyé à votre adresse e-mail.")
          })
          .catch((error) => {
            console.error(error)
            alert("Une erreur s'est produite lors de l'envoi de l'e-mail de réinitialisation de mot de passe.")
          })
      }
    }
  
  
      
    },
    computed: {
      // eslint-disable-next-line vue/return-in-computed-property
      getTotalAmount: function () {
        if (this.transactionType.id === 'particulier') {
          return this.amount * 1.06
        } else {
          return this.amount * 1.06
        }
      },
     
      getData: function () {
        if (this.wantValue.id !== 'particuler') {
          return {
            id: 'Facultatif',
            label: this.$t('cashycoin')
          }
        } else {
          return {
            id: '+221 78 111 41 25',
            label: 'du paiement'
          }
        }
      },
     
    },
    mounted() {
      // Définissez les données de la requête
      //  const requestData = {
     //     order_id: `NOLIDO_TRANSFERT_12`,
    //      reference: "NOLIDO_TRANSFERT",
   //       amount: 10,
   //       currency: "XOF",
    //      return_url: "https://www.google.com",
   //       cancel_url: "https://www.google.com",
    //      state: "tescct"
    //    };
  
      // Définissez les en-têtes de la requête
      // const headers = {
      //   'Content-Type': 'application/json',
      //   'country-code': 'sn',
      //   'category': 'SALE',
      //   'lang': 'fr',
      //   'authorization': 'Bearer 2122a551-8d10-3424-84ed-d72bf0fb6187'
      // };
  
      // Effectuez la requête POST en utilisant Axios
      // axios.post('/debitCard/v2', requestData, { headers })
      //   .then(response => {
      //     // Gérez les données de réponse
      //     this.responseData = response.data;
      //   })
      //   .catch(error => {
      //     // Gérez les erreurs
      //     console.error('Error:', error);
      //   });
    }
  
  }
  </script>
  
  <style lang="scss" scoped>
  
  @import "../../assets/style/sass/variables";

  .logo{
    background-color: $color-primary;
  }
  
  .create-transaction-button {
    background-color: $color-primary;
  }
  .logout {
    background-color: darkred;
  }
  .init-transaction-form {
    // background-color: red;
  }
  .next-button {
    background-color: $color-primary;
  }
  .select-mu /deep/ {
    .multiselect__tags {
      min-height: 55px !important;
      padding: 8px 45px 0 8px !important;
    }
    .multiselect__placeholder  {
      padding-top: 12px;
    }
    .multiselect__select:before {
      top: 85%;
    }
    .multiselect__single {
      line-height: 37px;
    }
  }
  .table-content {
    overflow-x: auto;
  }
  
  </style>
  <style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
  